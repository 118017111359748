/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p"
  }, _provideComponents(), props.components), {TableOfContents, LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "get-some-moxi",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#get-some-moxi",
    "aria-label": "get some moxi permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Get Some Moxi!"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Have you ever been on an important call yet unable to hear the person on the other line? This would be frustrating for you AND for the recipient of your call. If it’s a business call, this negative interaction could totally ruin future work opportunities, or if it’s a personal call, it could seriously damage a meaningful relationship. You need to be as secure in your phone etiquette as you are in your day-to-day interactions. With Unitron’s Moxi ", React.createElement(_components.a, {
    href: "/hearing-aids/",
    className: "c-md-a"
  }, "earing aid"), ", hearing loss doesn’t have to inhibit your ability to communicate in an increasingly digital society while not compromising on in-person quality."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "built-for-business",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#built-for-business",
    "aria-label": "built for business permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Built for Business"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The Moxi is optimized to handle all your most important calls from any device. It is compatible with multiple operating systems, not just iPhone. You shouldn’t have to select your hearing device based on the phone you currently own. That can get really expensive and add to the stress of hearing loss. The Moxi will work with any phone, so no need to hurt your wallet to achieve the best aural experience. Your calls will be directed straight to the Moxi, leaving your hands free to do whatever you want. The microphones in the hearing aid will transmit your voice directly to your phone so the experience is completely hands-free. If your work requires you to make and take frequent calls, then the Moxi is going to be your greatest asset. If not, you shouldn’t have to fear work like that. Your opportunities and abilities don’t have to be hindered because of hearing loss."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "efficient-all-day-charge",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#efficient-all-day-charge",
    "aria-label": "efficient all day charge permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Efficient All-Day Charge"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Unitron’s Moxi is the ultimate all-day hearing experience. You no longer need to feel self-conscious or anxious that your hearing will drop out in the middle of the day. Also, remembering to charge it completely is no longer an easily overlooked task. The Moxi charges at night on a base that the hearing aids are simply inserted in. It’s as convenient and unforgettable as taking off a pair of eyeglasses. You may ask, “What if I go beyond a typical 12-hour day?” Not a problem, because if you are burning the midnight oil, the Moxi rechargeable battery can be swapped out for a disposable one, so you never have to check out early and never have to compromise for a lack of hearing. The Moxi is the hearing aid that people with hearing loss have been asking for. A rechargeable, hands-free, and adaptable experience that doesn’t take over your life."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "tempus-revolutionizes-sound",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#tempus-revolutionizes-sound",
    "aria-label": "tempus revolutionizes sound permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Tempus Revolutionizes Sound"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Unitron’s Moxi uses a hearing technology called “Tempus” to tackle the number one issue people have with their hearing aids. Now you’ll be able to go to a concert and listen intently to the songs without having to fuss about hearing your friends. You’ll be able to handle your business without fear of interference or static. This technology is so good that 98% of hearing health industry professionals would recommend its use! When that many people stake their professional reputation on the line for a product, it must be worth it. You can find out for yourself!"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "apps-abound",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#apps-abound",
    "aria-label": "apps abound permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Apps Abound"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Unitron has a host of mobile applications designed to make your experience with the Moxi even better! With the uControl app, you’ll be able to send Unitron feedback about your device AND control detailed settings to maximize your comfort and enjoyment. uHear is an app to diagnose and test your hearing loss. You can download and use uHear today for free! There are apps for anything you could possibly want. Control and convenience are yours to command."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "help-getting-your-moxi",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#help-getting-your-moxi",
    "aria-label": "help getting your moxi permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Help Getting Your Moxi"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "We at hear.com are here for you! We are ready to help you find the best solution just for you. Simply fill out the form above and a dedicated hear.com expert will guide you through the entire process of finding the right hearing aids. We work hard to get you into the perfect set of hearing aids you want at the best price to fit your wallet. That’s not even all that they have to offer! They also provide you with a 45 day risk-free trial! We at hear.com will solve your hearing problem in a way that will reduce stress or pressure and make financial sense. Sign up today!"), "\n", React.createElement(LandingPageCta, {
    copy: "Try the Unitron Moxi now",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
